import { end, parse, Route, zero } from "fp-ts-routing";
import type * as t from "io-ts";

import { O } from "@scripts/fp-ts";
import type { ApiRedirectC, RelativeApiRedirect } from "@scripts/generated/models/apiRedirect";
import type { EmailUnsubscribeData, EmailUnsubscribeDataC } from "@scripts/generated/models/emailCampaign";
import type { LoginPageData, LoginPageDataC, ResetPasswordData, ResetPasswordDataC, ResetPasswordMfaData, ResetPasswordMfaDataC } from "@scripts/generated/models/login";
import type { PrivateSite, PrivateSiteC } from "@scripts/generated/models/privateSite";
import type { ConfirmAccountData, ConfirmAccountDataC } from "@scripts/generated/models/user";
import type * as V2Router from "@scripts/generated/routers/v2Router";
import { NotFound } from "@scripts/react/components/error/NotFound";
import type { PagesRouteWithRender } from "@scripts/routes/routing/ssr/pages";
import {
  accountInvite,
  accountInviteMatch,
  emailUnsubscribe,
  emailUnsubscribeMatch,
  login,
  loginMatch,
  mfaResetPassword,
  mfaResetPasswordMatch,
  notFound,
  privateSite,
  privateSiteMatch,
  resetPassword,
  resetPasswordMatch,
  reviewBLPTerms,
  reviewBLPTermsMatch,
  reviewTerms,
  reviewTermsMatch,
  signupMatch,
} from "@scripts/routes/routing/ssr/pages";

import { AccountInvitePage } from "../pages/AccountInvite";
import { EmailUnsubscribePage } from "../pages/EmailUnsubscribe";
import { LoginPage } from "../pages/LoginPage";
import { MFAResetPassword } from "../pages/MFAResetPassword";
import { PrivateSitePage } from "../pages/PrivateSite";
import { ResetPasswordPage } from "../pages/ResetPassword";
import { ReviewTermsPage } from "../pages/ReviewTerms";


const loginWithRender = (params: V2Router.BaseAuthControllerLoginParams, showCreateAccount: boolean) => ({
  ...login(params),
  render: (p) => <LoginPage {...p} showCreateAccount={showCreateAccount} />,
}) as const satisfies PagesRouteWithRender<LoginPageData, t.OutputOf<LoginPageDataC>>;

const resetPasswordWithRender = (params: V2Router.BaseAuthControllerConfirmPasswordLinkParams) => ({
  ...resetPassword(params),
  render: (p) => <ResetPasswordPage {...p} verificationId={O.none} />,
}) as const satisfies PagesRouteWithRender<ResetPasswordData, t.OutputOf<ResetPasswordDataC>>;

const mfaResetPasswordWithRender = (params: V2Router.BaseAuthControllerConfirmPasswordLinkMfaParams) => ({
  ...mfaResetPassword(params),
  render: (p) => <MFAResetPassword {...p} />,
}) as const satisfies PagesRouteWithRender<ResetPasswordMfaData, t.OutputOf<ResetPasswordMfaDataC>>;

const reviewTermsWithRender = (params: V2Router.BaseAuthControllerReviewTermsParams) => ({
  ...reviewTerms(params),
  render: (p) => <ReviewTermsPage redirect={p} variant="default" />,
}) as const satisfies PagesRouteWithRender<RelativeApiRedirect, t.OutputOf<ApiRedirectC>>;

const reviewBLPTermsWithRender = (params: V2Router.BaseAuthControllerReviewBLPTermsParams) => ({
  ...reviewBLPTerms(params),
  render: (p) => <ReviewTermsPage redirect={p} variant="blp" />,
}) as const satisfies PagesRouteWithRender<RelativeApiRedirect, t.OutputOf<ApiRedirectC>>;

const accountInviteWithRender = (params: V2Router.BaseAuthControllerConfirmInviteParams) => ({
  ...accountInvite(params),
  render: (p) => <AccountInvitePage {...p} />,
}) as const satisfies PagesRouteWithRender<ConfirmAccountData, t.OutputOf<ConfirmAccountDataC>>;

const emailUnsubscribeWithRender = (params: V2Router.BaseEmailUnsubscribeByHashParams) => ({
  ...emailUnsubscribe(params),
  render: (p) => <EmailUnsubscribePage {...p} />,
}) as const satisfies PagesRouteWithRender<EmailUnsubscribeData, t.OutputOf<EmailUnsubscribeDataC>>;

const privateSiteWithRender = {
  ...privateSite,
  render: (p) => <PrivateSitePage {...p} />,
} as const satisfies PagesRouteWithRender<PrivateSite, t.OutputOf<PrivateSiteC>>;

const notFoundWithRender = {
  ...notFound,
  render: () => <NotFound debugContent={O.none} />,
} as const satisfies PagesRouteWithRender<unknown, t.OutputOf<t.UnknownC>>;

export type PagesPageMeta = typeof notFoundWithRender
  | ReturnType<typeof loginWithRender>
  | ReturnType<typeof resetPasswordWithRender>
  | ReturnType<typeof mfaResetPasswordWithRender>
  | ReturnType<typeof reviewTermsWithRender>
  | ReturnType<typeof reviewBLPTermsWithRender>
  | ReturnType<typeof accountInviteWithRender>
  | ReturnType<typeof emailUnsubscribeWithRender>
  | typeof privateSiteWithRender;

export const router = zero<PagesPageMeta>()
  .alt(loginMatch.parser.map(_ => loginWithRender(_, false)))
  .alt(signupMatch.parser.map(_ => loginWithRender(_, true)))
  .alt(resetPasswordMatch.parser.map(resetPasswordWithRender))
  .alt(mfaResetPasswordMatch.parser.map(mfaResetPasswordWithRender))
  .alt(reviewTermsMatch.parser.map(reviewTermsWithRender))
  .alt(reviewBLPTermsMatch.parser.map(reviewBLPTermsWithRender))
  .alt(accountInviteMatch.parser.map(accountInviteWithRender))
  .alt(emailUnsubscribeMatch.parser.map(emailUnsubscribeWithRender))
  .alt(privateSiteMatch.parser.map(() => privateSiteWithRender))
  .alt(end.parser.map(() => notFoundWithRender));

export const parseLocation = (s: string): PagesPageMeta => parse(router, Route.parse(s), notFoundWithRender);
